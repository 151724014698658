<template>
    <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">Schedule a tour</div>
        <div class="bg-gray-200 mb-8 px-4">
            <div class="py-4">
                <div class="flex">
                    <div class="text-left">
                        <p class="font-bold text-lg uppercase">{{ getValue('sectionOneTitle') }}</p>
                        <p>{{ getValue('sectionOneDescription') }}</p>
                    </div>
                    <div class="ml-4">
                        <input type="text" class="mb-4 block"/>
                        <input type="text" class="mb-4 block"/>
                    </div>
                </div>
                <div class="flex mt-8">
                    <div class="text-left">
                        <p class="font-bold text-lg uppercase">{{ getValue('sectionTwoTitle') }}</p>
                        <p>{{ getValue('sectionTwoDescription') }}</p>
                    </div>
                    <div class="ml-4">
                        <input type="text" class="mb-4 block"/>
                        <input type="text" class="mb-4 block"/>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ]
}
</script>